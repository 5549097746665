const isCheckedAgreement = (value) => !!value;

export default {
  validations: {
    agreement: {
      required: isCheckedAgreement,
    },
  },
  computed: {
    isAgreementError() {
      return this.$v.agreement.$dirty && !this.$v.agreement.required;
    },
    agreementErrorText() {
      if (!this.$v.agreement.required) {
        return 'Please read and agree';
      }
    },
  },
};

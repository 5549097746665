import { minLength, helpers } from 'vuelidate/lib/validators';

const alphaNum = helpers.regex('alpha', /^[A-Za-z0-9]+$/);

export default {
  validations: {
    referralId: {
      alphaNum,
      minLength: minLength(8),
    },
  },
  computed: {
    isReferralIdError() {
      return (
        (this.$v.referralId.$dirty && this.$v.referralId.$invalid) ||
        !!this.getErrors.referral_code
      );
    },
    referralIdErrorText() {
      if (!this.$v.referralId.minLength) {
        return 'Referral ID must contains at least 8 characters';
      }
      if (!this.$v.referralId.alphaNum) {
        return 'Referral ID must contains only latin characters and digits';
      }
      if (!!this.getErrors.referral_code) {
        return this.getErrors.referral_code[0];
      }
    },
  },
};

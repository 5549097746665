import validationsMethods from '@/mixins/validation/common/validationMethods';
import firstNameValidation from '@/mixins/validation/fields/firstName';
import lastNameValidation from '@/mixins/validation/fields/lastName';
import emailValidation from '@/mixins/validation/fields/email';
import referralIdValidation from '@/mixins/validation/fields/referralId';
import passwordValidation from '@/mixins/validation/fields/passwords/password';
import confirmPasswordValidation from '@/mixins/validation/fields/passwords/confirmPassword';
import agreementValidation from '@/mixins/validation/fields/agreement';
import bscCoinValidation from '@/mixins/validation/fields/bsc_coin';
import srcxCoinValidation from '@/mixins/validation/fields/srcx_coin';
import cosmosCoinValidation from '@/mixins/validation/fields/cosmos';
import sourceCoinValidation from '@/mixins/validation/fields/source';

export default {
  mixins: [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    referralIdValidation,
    passwordValidation,
    confirmPasswordValidation,
    agreementValidation,
    bscCoinValidation,
    srcxCoinValidation,
    validationsMethods,
    cosmosCoinValidation,
    sourceCoinValidation,
  ],
  validations: {
    ...firstNameValidation.validations,
    ...lastNameValidation.validations,
    ...emailValidation.validations,
    ...referralIdValidation.validations,
    ...passwordValidation.validations,
    ...confirmPasswordValidation.validations,
    ...agreementValidation.validations,
    ...bscCoinValidation.validations,
    ...srcxCoinValidation.validations,
    ...sourceCoinValidation.validations,
    ...cosmosCoinValidation.validations,
  },
};

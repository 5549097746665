<template>
  <form class="signup-form" novalidate @submit.prevent>
    <h1 class="signup-form__title">Sign up</h1>
    <div class="signup-form__text text">
      We need you to help us with some basic information for your account
      creation
    </div>
    <div class="signup-form__items">
      <div class="signup-form__item">
        <LabelField
          id="firstname"
          class="signup-form__label"
          title="First name"
        />
        <TextField
          id="firstname"
          v-model.trim="firstName"
          name="firstname"
          required
          class="signup-form__input"
          type="text"
          :error="isFirstNameError"
          :error-txt="firstNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="lastname"
          class="signup-form__label"
          title="Last name"
        />
        <TextField
          id="lastname"
          v-model.trim="lastName"
          name="lastname"
          required
          class="signup-form__input"
          type="text"
          :error="isLastNameError"
          :error-txt="lastNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField id="email" class="signup-form__label" title="E-Mail" />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="auth-form__input"
          type="email"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text=""
          @focus="resetServerError('email')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="referral-id"
          class="signup-form__label"
          title="Referral ID <span>(optional)</span>"
        />
        <TextField
          id="referral-id"
          v-model.trim="referralId"
          name="referral-id"
          required
          class="signup-form__input"
          type="text"
          :error="isReferralIdError"
          :error-txt="referralIdErrorText"
          tooltip-text="Paste the code from the invitation"
          @focus="resetServerError('referral_code')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField id="password" class="signup-form__label" title="Password" />
        <TextField
          id="password"
          v-model.trim="password"
          name="password"
          required
          class="signup-form__input"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
          @focus="resetServerError('password')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="confirm-password"
          class="signup-form__label"
          title="Confirm password"
        />
        <TextField
          id="confirm-password"
          v-model.trim="confirmPassword"
          name="confirm-password"
          required
          class="signup-form__input"
          type="password"
          is-password
          :password-strength="confirmPasswordStrength"
          :error="isConfirmPasswordError"
          :error-txt="confirmPasswordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
        />
      </div>
    </div>
    <h3 class="signup-form__subtitle">Connect Wallet</h3>
    <div class="signup-form__items">
      <div class="signup-form__item">
        <LabelField
          id="bsc-recieve"
          class="signup-form__label"
          title="BSC Receive Address <span>(optional)</span>"
        />
        <TextField
          id="bsc-recieve"
          v-model.trim="bscRecieveAddress"
          name="bsc-recieve"
          required
          class="signup-form__input"
          connect-wallet-btn="metamask"
          :error="!!isBSCRecieveAddressError"
          :error-txt="BSCRecieveAddressErrorText"
          tooltip-text=""
          type="text"
          @connect-metamask-wallet="connectMetamaskBSCWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="srcx-recieve"
          class="signup-form__label"
          title="SRCX Receive Address <span>(optional)</span>"
        />
        <TextField
          id="srcx-recieve"
          v-model.trim="srcxRecieveAddress"
          name="srcx-recieve"
          required
          class="signup-form__input"
          connect-wallet-btn="metamask"
          :error="!!isSrcxRecieveAddressError"
          :error-txt="srcxRecieveAddressErrorText"
          tooltip-text=""
          type="text"
          @connect-metamask-wallet="connectMetamaskSRCXWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="source-recieve"
          class="signup-form__label"
          title="SOURCE Receive Address <span>(optional)</span>"
        />
        <TextField
          id="source-recieve"
          v-model.trim="sourceRecieveAddress"
          name="source-recieve"
          required
          class="signup-form__input"
          connect-wallet-btn="keplr"
          tooltip-text=""
          type="text"
          :error="!!isSourceRecieveAddressError"
          :error-txt="sourceRecieveAddressErrorText"
          @change="convertSourceAddressOnMobile"
          @connect-keplr-wallet="connectKeplrSourceWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="cosmos-recieve"
          class="signup-form__label"
          title="COSMOS Receive Address <span>(optional)</span>"
        />
        <TextField
          id="cosmos-recieve"
          v-model.trim="cosmosReceiveAddress"
          name="cosmos-recieve"
          required
          class="signup-form__input"
          connect-wallet-btn="keplr"
          tooltip-text=""
          type="text"
          :error="!!isCosmosRecieveAddressError"
          :error-txt="cosmosRecieveAddressErrorText"
          @connect-keplr-wallet="connectKeplrCosmosWallet"
        />
      </div>
    </div>
    <div class="signup-form__controls">
      <CheckboxField
        v-model="$v.agreement.$model"
        class="signup-form__checkbox"
        :error="isAgreementError"
        :error-txt="agreementErrorText"
      >
        <a href="/files/Source_Protocol_Ltd_Disclaimer_&_Privacy_Policy.pdf" target="_blank">
          I agree to the terms of the user agreement
        </a>
      </CheckboxField>
      <ButtonBase
        class="signup-form__btn"
        :loading="loading"
        size="max"
        @click="signupHandler"
        >Next</ButtonBase
      >
    </div>
  </form>
</template>

<script>
import signupValidation from '@/mixins/validation/forms/signup.js';
import CheckboxField from '@/elements/CheckboxField.vue';
import LabelField from '@/elements/LabelField';
import metamask from '@/mixins/metamask/metamask.js';
import keplr from '@/mixins/keplr/keplr.js';
import converter from 'convert-bech32-address';

export default {
  components: {
    LabelField,
    CheckboxField,
  },
  mixins: [signupValidation, metamask, keplr],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      referralId: '',
      password: '',
      confirmPassword: '',
      bscRecieveAddress: '',
      srcxRecieveAddress: '',
      sourceRecieveAddress: '',
      cosmosReceiveAddress: '',
      agreement: false,
      loading: false,
    };
  },
  computed: {
    signupData() {
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      if (!!this.referralId) {
        data.referral_code = this.referralId;
      }

      if (!!this.bscRecieveAddress) {
        data.bsc_receive_address = this.bscRecieveAddress;
      }

      if (!!this.srcxRecieveAddress) {
        data.srcx_receive_address = this.srcxRecieveAddress;
      }

      if (!!this.sourceRecieveAddress) {
        data.source_receive_address = this.sourceRecieveAddress;
      }

      if (!!this.cosmosReceiveAddress) {
        data.cosmos_receive_address = this.cosmosReceiveAddress;
      }
      return data;
    },
  },
  created() {
    const refererCode = this.$route.query.referral_code;
    if (!!refererCode) {
      this.referralId = refererCode;
    }
  },
  methods: {
    async signupHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.loading = true;
      await this.$store.dispatch('signup', this.signupData);
      this.loading = false;
    },
    async connectMetamaskBSCWallet() {
      this.bscRecieveAddress = await this.getMetamaskAddress();
    },
    async connectMetamaskSRCXWallet() {
      this.srcxRecieveAddress = await this.getMetamaskAddress();
    },
    async connectKeplrSourceWallet() {
      this.sourceRecieveAddress = await this.getKeplrAddress('source');
    },
    async connectKeplrCosmosWallet() {
      this.cosmosReceiveAddress = await this.getKeplrAddress('cosmos');
    },
    convertSourceAddressOnMobile(event) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        event.target.value !== ''
      ) {
        const result = converter.lookup(event.target.value, 'source');
        this.sourceRecieveAddress = result;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-form__text
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.signup-form__subtitle
  margin: 40px 0 24px 0
  font-weight: 600

.signup-form__items
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 24px 40px
  text-align: left
  @include l
    gap: 24px
  @include ms
    grid-template-columns: 1fr

.signup-form__checkbox
  margin: 32px 0
  @include ms
    margin: 24px 0

.signup-form__btn
  @include ms
    align-self: center
</style>
